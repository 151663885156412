<template>
    <div>
        <main class="container-fluid">
            <div class="row mx-md-4">
				<!-- Breadcrumb -->
				<div class="col-md-12 mb-4">
					<div class="card shadow border-0 round">
						<div class="card-body px-2 py-1">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb bg-transparent mb-0">
									<li class="breadcrumb-item text-info"><router-link to="/" class=" text-decoration-none">Home</router-link></li>
									<li class="breadcrumb-item active" aria-current="page">Panduan Belanja</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
				
				<div class="col-md-3 mb-4">
					<SidebarPanduan />
				</div>
				
				<div class="col-md-9 mb-4">
					<div class="card shadow border-0 text-secondary round px-5 py-4">
						<div class="text-center mb-4">
							<h2>Panduan Belanja</h2>
							<img src="@/assets/images/panduan-belanja.jpg" alt="panduan-belanja.jpg" class="w-75 round shadow">
						</div>
						<div class="text-left">
							<h6>Membuat Akun Duniamurah.com</h6>
							<p>Cara membuat akun Duniamurah.com  adalah dengan cara tekan opsi masuk di pojok kanan atas, lalu tekan daftar sekarang, setelah itu isi personal data anda  dan juga tekan centang yg ada dibawah untuk menyetujui privacy police, lalu tekan  daftar. Jika sudah terisi semua anda bisa langsung berbelanja di website duniamurah.com</p>
							<h6>Cara Memilih Barang</h6>
							<p>Pilih barang yg akan anda  beli dengan cara, mencari di kolom pencarian di bagian paling atas atau dapat juga bisa mencari melalui kategori.</p>
							<h6>Cara Memesan barang</h6>
							<p>Anda  tentukan banyak jumlah barang yang akan anda  pesan selanjutnya anda klik pada tombol <em>Keranjang</em> atau klik pada ikon <i class="fa fa-shopping-cart fa-sm"></i>.  Barang yang anda pesan  akan masuk ke keranjang belanja anda. proses selanjutanya anda dapat  mengisi data pengiriman.</p>
							<h6>Nego Harga</h6>
							<p>Untuk nego harga, masukkan harga yang anda inginkan kemudian tunggu persetujuan dari team kami . Jika Team kami  setuju maka anda akan diberikan link produk dengan harga yang sudah disetujui.</p>
						</div>
					</div>
				</div>
			</div>
        </main>
    </div>
</template>

<script>
import SidebarPanduan from '@/components/SidebarPanduan.vue'

export default {
	components: {
  		SidebarPanduan
	},
	created() {
		$(document).scrollTop(0)
	} 
}
</script>